import React from "react";
import PageBase from "../components/pageBase";
import LandingPage from "../components/landing";

// import LandingPage from "./landing";
// import AboutPage from "./about";
// import CareersPage from "./careers";
// import ContactPage from "./contact";

// markup
const IndexPage = () => {
  return (
    <PageBase>
      {/* return <h1>hi</h1>; */}
      <LandingPage/>
    </PageBase>
  );
};

export default IndexPage;
