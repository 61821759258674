import React from "react";
// import PageBase from "../pageBase";
import HeroBanner from "./hero";
import Benefits from "./benefits";
import Features from "./features";

const LandingPage = () => {
  return (
    <>
      <HeroBanner />
      <Benefits />
      <Features />
    </>
  );
};

export default LandingPage;
