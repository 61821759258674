import React from "react";
import PropTypes from 'prop-types'
import ShieldIcon from "../../assets/img/icons/shield.svg"
import ScaleIcon from "../../assets/img/icons/scale.svg"
import HandshakeIcon from "../../assets/img/icons/handshake.svg"
import IssuerCard from "../../assets/img/issuer_card.webp"
import IssuerList from "../../assets/img/issuer_list.webp"

import { Box, Tab, Tabs, Typography }from '@material-ui/core';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box className={`tabPanelContent`}>
          {children}
      </Box>
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Benefits = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabOne = () => {
    return (
      <>
      <div className="benefitsTabItem benefitsTabFirst">
        <div>
          <img src={ShieldIcon} className="svg-inject icon-svg icon-svg-md text-yellow me-4" alt="" />
        </div>
        <div>
          <h4 className="mb-1 mt-1">Unbiased Insights</h4>
          <p>Detailed unbiased credit risk insights based on macro-economic factors and company fundamentals.</p>
        </div>
      </div>
      </>
    )
  }

  const tabTwo = () => {
    return (
      <>
      <div className="benefitsTabItem">
        <div><img src={ScaleIcon} className="svg-inject icon-svg icon-svg-md text-green me-4" alt="" /></div>
        <div>
          <h4 className="mb-1 mt-1">Deep Coverage</h4>
          <p>Access insights on over 9,000 corporate bonds issued by over 800 companies.</p>
        </div>
      </div>
      </>
    )
  }

  const tabThree = () => {
    return (
      <>
        <div className="benefitsTabItem">
          <div><img src={HandshakeIcon} className="svg-inject icon-svg icon-svg-md text-red me-4" alt="" /></div>
          <div>
            <h4 className="mb-1 mt-1">Collaborative Partner</h4>
            <p>More than just a data provider, we work closely with your teams to ensure you achieve positive results.</p>
          </div>
        </div>
      </>
    )
  }

  return (
    <section className="wrapper bg-light">
      <div className="container py-14 pt-md-17">
        <div className="row">
          <div className="col-md-10 offset-md-1 col-lg-9 offset-lg-2 mx-auto text-center">
            <h2 className="fs-15 text-uppercase text-muted mb-3">Why Choose Migrations?</h2>
            <h3 className="display-4 mb-10 px-xl-10 px-xxl-15">Here are a few reasons why <br/>forward-thinking portfolio managers <br/>choose Migrations for credit analytics</h3>
          </div>
        </div>
        <Box sx={{ width: '100%' }}>
          <Box>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className="flex-lg-row justify-content-between">
              <Tab label={tabOne()} {...a11yProps(0)} className="benefitsTab" />
              <Tab label={tabTwo()} {...a11yProps(1)} className="benefitsTab" />
              <Tab label={tabThree()} {...a11yProps(2)} className="benefitsTab" />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
              <div className="col-lg-6">
                <div className="row gx-md-5 gy-5 align-items-center">
                  <div className="offset-2 col-8">
                    <img className="img-fluid rounded shadow-lg d-flex ms-auto" src={IssuerCard} alt="Unbiased Insights | Migrations.ml" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <h2 className="mb-3">Unbiased Insights</h2>
                <p>Our data products are developed using only macro-economic and company fundamentals data points, which means it is free from bias and can be used to assess the true risk of a company and the market. Bring quant and fundamental research into your workflow with minimal lift.</p>
                <ul className="icon-list bullet-bg bullet-soft-yellow">
                  <li><i className="uil uil-check"></i>Clear visibility into driving factors</li>
                  <li><i className="uil uil-check"></i>Solid foundation for enriching existing models</li>
                  <li><i className="uil uil-check"></i>Identify opportunities with trivial amount of energy</li>
                </ul>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
              <div className="col-lg-6 order-lg-2">
                <div className="row gx-md-5 gy-5">
                  <div className="col-10">
                    <img className="img-fluid rounded shadow-lg my-5 d-flex ms-auto" src={IssuerList} alt="Deep Issuer and Bond Coverage | Migrations.ml" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <h2 className="mb-3">Deep Coverage</h2>
                <p>Access credit insights on over 9,000 corporate bonds across 800 issuers at both issuance and issuer-levels with more to come. Don't have what you're looking for? Let us know and we'll add it to our inventory.</p>
                <ul className="icon-list bullet-bg bullet-soft-green">
                  <li><i className="uil uil-check"></i>Scale up your credit analysis process</li>
                  <li><i className="uil uil-check"></i>Covers both investment grade and high yield bonds</li>
                  <li><i className="uil uil-check"></i>See across the market and into individual issuers</li>
                </ul>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
              <div className="col-lg-6">
                <div className="row gx-md-5 gy-5">
                  <div className="col-10">
                    <img className="img-fluid rounded shadow-lg mb-5" src={IssuerList} alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <h2 className="mb-3">Collaborative Partner</h2>
                <p>Migrations.ML has experience helping our clients with data engineering, process design and model construction. <a href="mailto:info@migrations.ml">Contact us</a> today to learn how we can accelerate delivery across the whole project lifecycle.</p>
                <ul className="icon-list bullet-bg bullet-soft-red">
                  <li><i className="uil uil-check"></i>Support tailor-made for your specific use cases</li>
                  <li><i className="uil uil-check"></i>Leverage our team's data and AI expertise</li>
                </ul>
              </div>
            </div>
          </TabPanel>
        </Box>
      </div>
    </section>
  );
};

export default Benefits;
