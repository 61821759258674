import React from "react";
import LightBulb from "../../assets/img/icons/light-bulb.svg"
import Puzzle from "../../assets/img/icons/puzzle-2.svg"
import Shield from "../../assets/img/icons/shield.svg"
import Search from "../../assets/img/icons/search.svg"



const Features = () => {
  return (
    <section className="wrapper bg-light features">
      <div className="container py-5 py-md-5">
        <div className="gy-10 gy-sm-13 gx-lg-3 align-items-center mb-5 row">
          <div className="mx-auto text-center col-lg-9 col-md-10 offset-lg-2 offset-md-1">
            <h2 className="fs-15 text-uppercase text-muted mb-3">How it works?</h2>
            <h3 className="display-4 mb-10 px-xl-10 px-xxl-15">Ways where Migrations<br/>credit analytics helps </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div className="d-flex flex-row mb-5">
                <div>
                  <img src={LightBulb} className="svg-inject icon-svg icon-svg-md text-yellow me-5" alt="" />
                </div>
                <div>
                  <h4 className="mb-1">New investment opportunities</h4>
                  <p className="mb-0">Identify new trading opportunities by monitoring discrepancies between OAS and Migrations spread.</p>
                </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="d-flex flex-row mb-5">
              <div>
                <img src={Puzzle} className="svg-inject icon-svg icon-svg-md text-green me-5" alt="" />
              </div>
              <div>
                <h4 className="mb-1">Model Enrichment</h4>
                <p className="mb-0">Enrich existing default models, forecasted losses and trading strategies.</p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="d-flex flex-row mb-5">
              <div>
                <img src={Shield} className="svg-inject icon-svg icon-svg-md text-red me-5" alt="" />
              </div>
              <div>
                <h4 className="mb-1">Portfolio Risk Management</h4>
                <p className="mb-0">Understand existing portfolio risk in accordance with current market conditions.</p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="d-flex flex-row mb-5">
              <div>
                <img src={Search} className="svg-inject icon-svg icon-svg-md text-green me-5" alt="" />
              </div>
              <div>
                <h4 className="mb-1">Counterparty Risk Monitoring</h4>
                <p className="mb-0">Develop a robust counterparty risk management framework that limits portfolio risk exposure while maximizing returns.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
